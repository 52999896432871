<template>
  <div class="conteudos_tematicos_bg">
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div class="mt-md-5">
          <p
            style="background-color: #DC91B9; padding: 0.5em; border-radius: 4px; font-size: 1em;"
          >
            <strong> Trabalho e renda </strong>
          </p>
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O solapamento de moradias, trabalhos e atividades produtivas
                  tem consequências particularmente mais graves para as
                  mulheres: o notório adoecimento dos familiares e os desafios
                  da nova vida imposta sobrecarregam mais as pessoas que são
                  socialmente entendidas como responsáveis pelo cuidado, o que
                  se intensifica diante da perda da rede de apoio provocada pelo
                  espalhamento dos núcleos familiares pela cidade de Mariana.
                </p>
                <p>
                  A maioria das comunidades atingidas encontra-se, hoje,
                  inabitável. O núcleo urbano de Mariana, principal destino para
                  alocação das famílias atingidas, exigia dessas pessoas uma
                  adequação profunda das competências e habilidades para
                  eventuais situações de trabalho, uma vez que as principais
                  atividades rurais não existem no centro urbano.
                </p>
                <p>
                  Em relação à perda da habitação, muitas mulheres estavam em
                  fase de construção de suas casas, sonho que infelizmente foi
                  interrompido desde o rompimento da barragem. Em alguns casos,
                  as casas em construção foram destruídas pela lama. Em outros,
                  as casas tiveram que ser habitadas mesmo antes de finalizadas,
                  em razão da situação precária de não ter para onde ir por
                  falta de amparo das instituições promotoras do desastre–crime.
                  Essa perda patrimonial provoca dores existenciais
                  inimagináveis nas pessoas atingidas, como é o caso desse
                  depoimento de uma senhora atingida durante a tomada de termo
                  realizada em 2020:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_06.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Larissa Helena. Jornal A Sirene, 2017.
          </p>
        </v-col>
      </v-row>
    </section>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “[...] antes do rompimento, estava construindo uma casa para morar.
            Eu pagava aluguel, e quando fiquei desempregada, só botei o telhado
            para me mudar pra lá, sem deixar tudo como gostaria.
            <strong>Minha vida está provisória</strong>. Eu engordei em razão
            dos medicamentos que passei a tomar após o rompimento. Percebo que
            as pessoas me veem de um modo diferente em decorrência das doenças
            adquiridas após o rompimento.”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Em decorrência do desastre-crime, para essas mulheres
                  atingidas, iniciou-se uma vida pós-desastre criminoso permeada
                  por ansiedade, insônia e depressão. Essas são algumas das
                  principais mazelas relatadas nas
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_tomadas_de_termo' }"
                    >tomadas de termo</router-link
                  >, realizadas durante o processo de cadastramento por mulheres
                  que se viram totalmente desestabilizadas após o rompimento,
                  sem emprego, sem os cultivos, sem a comunidade e sem a sua
                  antiga vida de trabalho e de autonomia. Mais de 99,98% das
                  mulheres maiores de 18 anos declararam no cadastramento
                  realizado pela Cáritas em Mariana algum tipo de dano à
                  integridade psíquica. Ao todo, 64,82% das atingidas maiores de
                  idade enquadram-se em ao menos quatro danos referentes à
                  integridade psíquica.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_07.png"></v-img>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Para representar a dor vivenciada pelas mulheres atingidas,
                  traz-se aqui o relato de uma senhora atingida, que recebeu da
                  Fundação Renova carta de
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{
                      name: 'conteudos_tematicos_ser_atingido_nao_reconhecidos',
                    }"
                    >inelegibilidade<sup>2</sup> para indenização</router-link
                  >. Ela conta que antes do rompimento a sua rotina era
                  trabalhar de segunda a sexta-feira e sair aos fins de semana
                  para lazer. Desde o rompimento, essa mulher, que é mãe, ficou
                  desempregada e, com tamanha tristeza, perdeu a vontade de sair
                  de casa. Nas palavras dessa mulher, “a minha vida perdeu o
                  sentido”. Ela explica durante a tomada de termo realizada em
                  2020:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image2"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “[...] antes do rompimento, minha saúde era normal e eu não tomava
            nenhum medicamento e dormia bem, mas após a tragédia passei a ter
            hipertensão. Passei a usar “hidroclorazatina 25 mmg” e “Losartana 50
            mmg” para controlar a pressão. Eu cheguei a ter “surtos”
            psicológicos. Eu não lembro do que ocorreu nestes “surtos”, só sei
            de relatos de familiares. Também passei a usar medicamento
            controlado, como “Haldol”, “Clonazepan” e “fluroxetina”, para
            tratamento de ansiedade e dos transtornos psiquiátricos. Hoje não
            gosto de sair de casa porque me sinto mal. Faço acompanhamento no
            posto de saúde e cheguei a ser atendida no CAPS após o rompimento.
            Mas não tive problemas com falta de medicações fornecidas pelo
            município de Mariana. E também não tive contato com a lama de
            rejeitos. Na época do rompimento, trabalhava em um restaurante e
            percebi que o contato com funcionários das empresas que lá faziam
            suas refeições e relatavam o que viam na lama, prejudicaram minha
            saúde mental. Hoje eu tenho medo que possa ocorrer um novo
            rompimento.”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Nas demais comunidades atingidas, a situação angustiante
                  parece ser compartilhada pelas mulheres. A Sra. Maria das
                  Graças, de Bento Rodrigues, relata em entrevista para a
                  Cáritas Brasileira Regional Minas Gerais que na comunidade ela
                  tinha casa própria e não pagava aluguel. Hoje, vive a
                  insegurança da dependência do custeio da moradia pela Fundação
                  Renova. Segundo ela:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image3"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “A Renova é obrigada a pagar o aluguel porque ela tirou a casa da
            gente, não é não? Tirou o nosso modo de vida. Na minha casa eu tinha
            pé de laranja, pé de mexerica, tinha verduras, tinha tudo! Era
            pequenininho, mas ali eu tinha tudo. Hoje em dia eu tenho que
            comprar tudo. Nossos modos de vida eram totalmente diferentes, a
            gente não pagava aluguel, a gente tinha a casa da gente. Hoje eles
            pagam aluguel pra gente por quê? Eles detonaram com a vida da gente
            (Maria das Graças, 2021). “
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Há ainda as famílias que recebem Auxílio Financeiro
                  Emergencial (AFE) e se tornam dependentes financeiros da
                  Fundação Renova, gerando insegurança com a possibilidade de
                  perderem esse recurso caso surja outro entendimento jurídico.
                  Para aquelas que têm familiares como dependentes de sua renda,
                  como crianças e idosos, por exemplo, o cenário é ainda mais
                  trágico, violento e solitário. Uma mulher atingida conta ao
                  jornal A Sirene (2018) que:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Eu pescava, eu e meu marido, pro sustento do lar, mas, quando
            sobrava a gente vendia. Era um dinheirinho extra que entrava. Os
            peixes que a gente pescava ajudava no orçamento da casa. Agora vai
            fazer o quê? Abalou a gente, foi uma coisa assim, sem esperar. Eu
            estou tomando remédio de depressão depois disso aí, porque era um
            lazer que a gente tinha, aquela paisagem para distrair. Agora não
            tem como, acabou. Eu fiz o Cadastro em dezembro e ainda não fui
            reconhecida. Eles não dão previsão de quando dar retorno, só ficam
            enrolando a gente (CASTRO et al., 2018).”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Há também a situação das inúmeras mulheres que viram diversos
                  parentes adoecerem com o desastre–crime, precisaram assumir o
                  cuidado e, por vezes, funções dessas pessoas, além da tripla
                  jornada de trabalho que já exerciam envolvendo o trabalho
                  externo, o doméstico e o cuidado com familiares e pessoas
                  próximas mais vulneráveis, como crianças e idosos de maneira
                  geral. A Sra. Maria do Carmo D’ ngelo, moradora de Paracatu de
                  Cima, conta sobre sua lida diária de conciliar os afazeres de
                  casa com a luta por direitos desde o rompimento:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image4"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “<strong
              >A gente tem que dar atenção pra família e ainda tirar tempo pra
              lutar</strong
            >. De manhã eu levanto, faço tudo correndo dentro de casa. Faço o
            almoço, arrumo casa, lavo roupa. Eu ponho a roupa pra lavar de noite
            e, de manhã, dou um jeito de pôr no varal. Quando nós fomos
            reelaborar o Cadastro, eu fiquei seis meses cuidando dos meus
            afazeres à noite. Fazia tudo à noite pra de manhã vir pra Mariana.
            Era muito sofrido. Quando fomos discutir o uso do tempo, a Renova
            falou que era facultativo participar, que nós fizemos aquilo porque
            queríamos. A revolta foi tanta. Se eles tivessem feito um Cadastro
            decente, não precisava fazer de novo. Aí eu falei: “Tá, e quanto que
            vocês ganharam pra vir aqui tirar direito de nós?” (RODRIGUES et
            al., 2019).”
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center">
        <v-col cols="11" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Ao que se observa nos relatos, as práticas institucionais das
                  mineradoras no território de Mariana reforçam papéis de gênero
                  e dificultam, economicamente, o desenvolvimento e a
                  independência das mulheres atingidas. Mesmo após seis anos do
                  desastre-crime, poder retomar os modos de vida habituais
                  parece ser uma realidade ainda distante para essas mulheres,
                  pela falta de perspectiva patrimonial e existencial em que se
                  encontram por culpa das empresas rés.
                </p>
                <p>
                  O jornal A Sirene de outubro de 2021 (PAPAGAIO, 2021) conta
                  sobre duas fomes que são vividas no território pelas mulheres
                  atingidas: “uma fome física, pois elas não podem comer a
                  produção de seus quintais, por medo da contaminação dos metais
                  pesados, e uma fome de justiça perante o crime da Samarco
                  ainda sem solução".
                </p>
                <p>
                  Além dos traumas pela fuga da lama de rejeitos, as atingidas
                  convivem ainda com a marginalização, fruto de uma má
                  interpretação da realidade pelos outros moradores da cidade.
                  As atingidas estão, em sua maioria, sem emprego, sem casa
                  própria e sem autonomia por culpa das mineradoras, o que nem
                  sempre é compreendido de forma clara pela população à sua
                  volta. De fato, essa distorção da realidade acaba por
                  penalizar, de forma drástica, a realidade já oprimida das
                  atingidas.
                </p></template
              >
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="8"
          class="d-flex justify-center align-center flex-column"
        >
          <v-img src="../../assets/conteudo_genero_08.png"></v-img>
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Foto: Larissa Pinto. Jornal A Sirene, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Os cálculos foram feitos a partir de categorização e
              tabelamento dos
              <router-link
                tag="a"
                style="text-decoration: none"
                class="pink--text"
                :to="{
                  name: 'sobre_sistematizacao_dossie',
                }"
                >danos imateriais</router-link
              >
              declarados durante o cadastramento realizado pela Cáritas. Foram
              analisadas as informações de uma amostragem de 1.174 mulheres,
              tendo sido aleatoriamente consideradas dentre o universo das
              atingidas cadastradas que apresentavam mais de 18 anos no momento
              do rompimento e que passaram pela etapa individual da tomada de
              termo.
            </p>
            <p>
              <sup>2</sup> Para mais informações, acesse o Parecer Técnico
              Jurídico da Cáritas Brasileira Regional Minas Gerais em:
              <a
                class="pink--text"
                style="text-decoration: none;"
                href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf"
                target="_blank"
                >http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf.</a
              >
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Gênero /",
          href: "conteudos_tematicos_genero",
        },
        {
          text: "Trabalho e Renda",
          href: "conteudos_tematicos_trabalho_renda",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }
  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
